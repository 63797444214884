import React, { useState } from "react"
import SinglePageHeader from "../components/SinglePageLayout"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import axios from "axios"
import dm from "dompurify"
import { useTranslation } from "gatsby-plugin-react-i18next"
// import logob64 from "./logo.js"
import Mit from "markdown-it"

const defaultImg =
  "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1867&q=80"

export default function Newsletter({ data }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const postData = data.markdownRemark.frontmatter
  async function donwloadPDF() {
    const md = new Mit({
      html: true,
      breaks: true,
      linkify: true,
      highlight: true,
    })
    const styleBuff = new Blob([
      `
      @import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
      body {
        font-family: 'PT Serif', serif;
        font-size: 14pt;
      }
      h1 {
          border-bottom: solid #CCC 4px;
      }`,
    ])

    // <img src="data:image/png;base64,${logob64}" alt="img" style="width: 60px;">
    const buffer = new Blob(
      [
        `
        <html>
        <head>
        <link rel="stylesheet" href="style.css" />
        </head>
        <body>
        <h2>${postData.title}</h2><i>${new Date(
          postData.datetime
        ).toDateString()}</i><hr/><div>${dm
          .sanitize(md.render(postData.content))
          .toString()}</div><hr/><p>${postData.author}</p><i>${
          postData.authorTitle
        }</i>
        <p><small>You can read this newspaper at <a href="${
          document.URL
        }">ICH Website</a>.</small></p>
        </body></html>`,
      ],
      {
        type: "text/html",
      }
    )
    const file = new FormData()
    file.append("files", buffer, "index.html")
    file.append("files", styleBuff, "style.css")
    setLoading(true)
    axios({
      method: "post",
      url: "https://functions.decafhub.com/function/gotenberg/convert/html",
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `ICH-${postData.title.replace(/ /g, "-")}.pdf`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch(_err => {
        alert("Error while downloading PDF file. Please try again later...")
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <SinglePageHeader
      title={postData.title}
      bg={postData.headerImage || defaultImg}
    >
      <section className="ich-post-wrapper">
        <article className="ich-post ich-container">
          <h3 className="post-title">{postData.title}</h3>
          <p className="post-date">
            {new Date(postData.datetime).toDateString()}
          </p>
          <hr />
          <Markdown>{postData.content}</Markdown>
          <hr />
          <p className="post-author-name">{postData.author}</p>
          <i className="post-author-title">{postData.authorTitle}</i>
        </article>
      </section>
      <button
        className="pink-button"
        onClick={donwloadPDF}
        style={{ marginBottom: 40, fontSize: 14 }}
        disabled={loading}
      >
        {loading ? (
          <>
            <span style={{ marginRight: 10 }}>Please wait...</span>
            <i className="fas fa-spinner fa-spin" />
          </>
        ) : (
          t("download-the-newsletter")
        )}
      </button>
    </SinglePageHeader>
  )
}

export const pageQuery = graphql`
  query NewsletterTemplateQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        datetime
        content
        author
        authorTitle
        headerImage
      }
    }
  }
`
